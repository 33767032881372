import { differenceInMinutes } from 'date-fns';
import App, { AppContext, AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';
import { TagPageProps } from 'src/pages/tags/[slug]';

import api from '@/helpers/api';
import {
  BannerInitialContext,
  getInitialBannerContext,
} from '@/helpers/bannerContext';
import { DeviceProvider } from '@/helpers/deviceContext';
import deviceDetector, { DeviceType } from '@/helpers/deviceDetector';
import { getPlatform, isNewizvDomain } from '@/helpers/getInstance';
import { getFromMemcached } from '@/helpers/memcached';
import { PlatformProvider } from '@/helpers/platformContext';
import { SlugEnum } from '@/helpers/slugEnums';
import {
  HeaderBiddingsSettingsType,
  PlatformSettingsType,
  PlatformType,
} from '@/helpers/types';

import Layout from '@/components/Layout';
import {
  ToplineStateProvider,
  ToplineStateType,
} from '@/components/Topline/toplineContext';

import '@/styles/globals.scss';

type PropsType = AppProps & {
  platform: PlatformType;
  device: DeviceType;
  bannersHeight: Record<`${SlugEnum}`, string>;
};

function MyApp({
  Component,
  pageProps,
  platform,
  device,
  bannersHeight,
}: PropsType): ReactNode {
  const router = useRouter();
  const initialToplineState = {} as ToplineStateType;
  if (router.pathname === '/tags/[slug]') {
    const tag = (pageProps as TagPageProps).tag;
    if (!tag.image) {
      initialToplineState.isTransparent = false;
    }
  }

  // TODO: убрать, когда бэк начнёт отдавать settings_custom
  if (platform?.settings_custom?.header_biddings === undefined) {
    if (!platform?.settings_custom) {
      platform.settings_custom = {} as PlatformSettingsType;
    }

    const biddersCpmAdjustmentMap = {
      sape: 0.8,
      myTarget: 0.65,
    };

    const biddings: { [key: string]: HeaderBiddingsSettingsType } = {
      default: {
        biddersMap: {
          myTarget: 1145142,
          betweenDigital: 1145140,
          adriver: 1145141,
          buzzoola: 2934032,
          otm: 1535183,
          hybrid: 1911170,
          mediasniper: 2219872,
          sape: 2934027,
          adfox_adsmart: 1145158,
          adfox_WiseUP: 3130673,
          'adfox_roden-media': 2934025,
          'adfox_yandex_adfox@monetize-me.ru': 2726487,
        },
        adUnits: [],
        timeout: 1200,
      },
      newizv: {
        biddersMap: {
          myTarget: 927305,
          betweenDigital: 811421,
          adriver: 1023386,
          otm: 1535223,
          hybrid: 2205294,
          mediasniper: 2219686,
          sape: 2922261,
          buzzoola: 2931719,
          adfox_WiseUP: 1023470,
          'adfox_yandex_adfox@monetize-me.ru': 2922259,
          'adfox_roden-media': 2931736,
          adfox_adsmart: 1023476,
        },
        adUnits: [],
        timeout: 1200,
        biddersCpmAdjustmentMap: biddersCpmAdjustmentMap,
      },
    };

    const isNewizv = isNewizvDomain(platform);

    platform.settings_custom.header_biddings = isNewizv
      ? biddings.newizv
      : biddings.default;
  }

  return (
    <DeviceProvider value={device}>
      <PlatformProvider value={platform}>
        {/* @ts-expect-error @TODO fix types */}
        <ToplineStateProvider initialState={initialToplineState}>
          <BannerInitialContext
            value={getInitialBannerContext(pageProps, bannersHeight)}
          >
            {/* @ts-expect-error @TODO fix types */}
            <Layout>
              {/* key prop https://github.com/vercel/next.js/issues/9992#issuecomment-910459956 */}
              <Component {...pageProps} key={router.asPath} />
            </Layout>
          </BannerInitialContext>
        </ToplineStateProvider>
      </PlatformProvider>
    </DeviceProvider>
  );
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  // browser context, rare situation (404 page for instance)
  if (typeof window !== 'undefined') {
    const device = deviceDetector(window.navigator.userAgent);
    const [appProps, { data: platform }] = await Promise.all([
      App.getInitialProps(appContext),
      api.get<PlatformType>('platform'),
    ]);
    return { ...appProps, platform, device };
  }

  // server context
  const req = appContext.ctx.req;
  if (!req) return { notFound: true };
  const device = deviceDetector(req.headers['user-agent'] || '');
  // calls page's `getInitialProps` and fills `appProps.pageProps`
  // also get platform, use Promise.all for parallel queries to speed up page load
  const [appProps, platformResult] = await Promise.all([
    App.getInitialProps(appContext),
    getPlatform(req),
  ]);

  if ('notFound' in platformResult) {
    return platformResult;
  }

  const date = await getFromMemcached(
    // `${platformResult.platform.domain}:${SlugEnum.all_header_before}`
    `${platformResult.platform.domain}:${SlugEnum.all_header_before}`,
  );

  return {
    ...appProps,
    platform: platformResult.platform,
    device,
    bannersHeight: {
      [SlugEnum.all_header_before]: date
        ? Math.abs(differenceInMinutes(new Date(), new Date(date))) < 1 &&
          '200px'
        : '0',
    },
  };
};

export default MyApp;
