import axios from 'axios';

export default async function setBannerDate({
  domain,
  slug,
}: {
  slug: string;
  domain: string;
}): Promise<void> {
  try {
    await axios.get(
      `/set-state?key=${domain}:${slug}&value=${new Date().toISOString()}`,
    );
  } catch (e) {
    console.error(e);
    return;
  }
}
