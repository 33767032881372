import styles from './styles.module.scss';

const BottomFixedStack = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  return <div className={styles.container}>{children}</div>;
};

export default BottomFixedStack;
