import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { IncomingMessage } from 'http';

type ConfigType = AxiosRequestConfig & {
  req?: IncomingMessage;
};

// TODO refactor that
function getApiSettings(req?: IncomingMessage): {
  domain: string;
  apiHost: string;
} {
  let domain: string;
  if (req) {
    domain = req.headers.host as string;
  } else {
    domain = location.host;
  }
  if (domain.includes('localhost') || process.env.NEXT_PUBLIC_SITE_DOMAIN) {
    domain = process.env.NEXT_PUBLIC_SITE_DOMAIN ?? 'last.dev.1mi.media';
  }
  const apiHost = process.env.NEXT_PUBLIC_API_HOST ?? `https://${domain}/api`;
  return { domain, apiHost };
}

function send<T>(
  url: string,
  data: unknown = null,
  config: ConfigType = {},
  method: 'get' | 'post' = 'get',
): Promise<AxiosResponse<T>> {
  const req = config.req;
  // axios doesnt need that
  delete config.req;
  const { domain, apiHost } = getApiSettings(req);
  const origin =
    typeof window === 'undefined'
      ? process.env.NEXT_PUBLIC_API_DOCKER_ORIGIN || 'http://api:3000'
      : apiHost;
  url = url.startsWith('http') ? url : `${origin}/site/${url}`;

  const configWithHeades = { ...config, headers: { domain } };
  return method === 'get'
    ? axios.get<T>(url, configWithHeades)
    : axios.post<T>(url, data, configWithHeades);
}

const api = {
  get: <T>(url: string, config?: ConfigType): Promise<AxiosResponse<T>> =>
    send<T>(url, null, config, 'get'),
  post: <T>(
    url: string,
    data: unknown,
    config?: ConfigType,
  ): Promise<AxiosResponse<T>> => send<T>(url, data, config, 'post'),
  getApiSettings,
};

declare global {
  interface Window {
    api?: typeof api;
  }
}

// api in browser for debugging
if (typeof window !== 'undefined') {
  window.api = api;
}

export default api;

export async function serverApi<T = unknown>(
  url: string,
  config: ConfigType,
  fallback?: T,
): Promise<{ data: T; cached: boolean } | { notFound: true }> {
  try {
    const result = await api.get<T>(url, config);
    const cached = result.headers['x-cache-any'] === 'HIT';
    return { data: result.data, cached };
  } catch (e) {
    // fallback
    if (fallback) return { data: fallback, cached: false };

    // 404
    if (axios.isAxiosError(e) && e.response?.status === 404) {
      return { notFound: true };
    }
    // axios error or basic error
    if (axios.isAxiosError(e) || e instanceof Error) {
      console.error('url, config:');
      console.error(url, config);
      console.error('e.message: ', e.message);
      throw new Error(e.message);
    }
    // this branch never should happen

    console.error(url, config);
    console.error(e);
    throw new Error('Неизвестная ошибка');
  }
}
