import Head from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';

import { usePlatform } from '@/helpers/platformContext';
import { ImageType, WithMetaType } from '@/helpers/types';

export type SeoItemType = {
  title: string;
  header: string;
  hostname: string;
  description?: string;
  image?: ImageType;
  path?: string;
  published_at?: string;
  updated_at?: string;
  authorTags?: string[];
  tags?: string[];
  rubricTags?: string[];
  themeTags?: string[];
  noindex?: boolean;
  breadcrumbListForEachTag?: boolean;
} & WithMetaType;

const SeoData: React.FC<{
  item: SeoItemType;
}> = ({ item }): React.ReactElement => {
  const router = useRouter();
  const platform = usePlatform();
  const meta = item.meta_information_attributes;
  const title = meta?.title || item.title;
  const header = item.header;
  const description = meta?.description || item.description;
  const pathname = item?.path || router.asPath.split('?')[0];
  const isMainPage = pathname === '/';
  const canonicalUrl = `${item.hostname}${isMainPage ? '' : pathname}`;
  const image = item.image ? `${item.hostname}${item.image.image_1920}` : '';

  const ldJson = {
    '@context': 'http://schema.org',
    '@graph': [
      item.rubricTags && item.rubricTags?.length > 0
        ? {
            '@type': 'BreadcrumbList',
            itemListElement: item.rubricTags.map((name, index) => ({
              '@type': 'ListItem',
              position: index + 1,
              item: {
                '@id': name,
                name,
              },
            })),
          }
        : null,
      item.breadcrumbListForEachTag && item.tags && item.tags.length > 0
        ? item.tags.map((name) => ({
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                item: {
                  '@id': name,
                  name,
                },
              },
            ],
          }))
        : null,
      {
        '@type': 'Article',
        '@id': header,
        headline: header,
        url: canonicalUrl,
        author: item.authorTags?.map((name) => ({ name })) || [],
        image: image,
        articleSection: item.rubricTags,
        datePublished: item.published_at,
        dateModified: item.updated_at,
        about: item.themeTags?.map((name) => ({ name })) || [],
      },
    ].filter(Boolean),
  };

  const robots = item.noindex ? 'noindex, nofollow' : 'max-image-preview:large';

  return (
    <Head>
      <title key="title">{title}</title>
      <meta property="og:title" content={header} key="og-title" />
      <meta
        property="og:description"
        name="description"
        content={description}
        key="description"
      />
      {isMainPage && platform.logo_png_url && (
        <>
          <meta
            property="og:image"
            content={canonicalUrl + platform.logo_png_url}
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:image"
            content={canonicalUrl + platform.logo_png_url}
          />
        </>
      )}
      meta?.keywords && (
      <meta
        property="og:keywords"
        name="keywords"
        content={meta?.keywords}
        key="keywords"
      />
      )
      {item.tags?.map((tag) => (
        <meta
          property="article:tag"
          name="tag"
          content={tag}
          key={`tag ${tag}`}
        />
      ))}
      <meta property="og:url" content={canonicalUrl} />
      <link rel="canonical" href={canonicalUrl} key="canonical"></link>
      {Boolean(image) && <meta property="og:image" content={image} key="url" />}
      <meta
        property="og:type"
        content={isMainPage ? 'website' : 'article'}
        key="type"
      />
      <meta name="robots" key="robots" content={robots} />
      {!isMainPage && (
        <script
          type="application/ld+json"
          key="schema-org"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(ldJson) }}
        />
      )}
    </Head>
  );
};

export default SeoData;
