import MemcachedType from 'memcached';

let client: MemcachedType;

// dont use memcached in dev mode by default, see package.json
if (typeof window === 'undefined' && !process.env.NO_CACHE) {
  void import('memcached').then(({ default: Memcached }) => {
    client = new Memcached('memcached:11211');
  });
}

export function getFromMemcached(key: string): Promise<string> {
  return new Promise((resolve, reject) => {
    if (!client) return resolve('');
    client.get(key, (err, data) => {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
}

export function setInMemcached(
  key: string,
  value: string,
  lifetime: number,
): Promise<boolean> {
  return new Promise((resolve, reject) => {
    if (!client) {
      return reject(new Error('No memcached client'));
    }
    client.set(key, value, lifetime, (err) => {
      if (err) {
        reject(err);
      } else {
        resolve(true);
      }
    });
  });
}
