import dynamic from 'next/dynamic';
import React, { useEffect, useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

import {
  hideTransversalBanner,
  useBannerInitialContext,
} from '@/helpers/bannerContext';
import { useDevice } from '@/helpers/deviceContext';
import { usePlatform } from '@/helpers/platformContext';
import { SlugEnum } from '@/helpers/slugEnums';
import { BannerIndexPropsType, BannerType } from '@/helpers/types';

import ErrorBoundary from '../ErrorBoundary';

const HtmlBanner = dynamic(() => import('./HtmlBanner'), { ssr: false });
const AdfoxBanner = dynamic(() => import('./AdfoxBanner'), { ssr: false });
const BannerTemplate = dynamic(() => import('./BannerTemplate'), {
  ssr: false,
});

function isVisible(banner: BannerType): boolean {
  return banner.publish;
}

const Banner: React.FC<BannerIndexPropsType> = ({
  slug,
  className,
  reload,
  onRender,
}): React.ReactElement => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { banners, contacts } = usePlatform();
  const device = useDevice();
  const banner = banners
    ? banners.find((el) => el.slug === slug && isVisible(el))
    : null;

  const { hide_banners, bannersHeight } = useBannerInitialContext();
  const [height, setHeight] = useState<string | undefined>(
    bannersHeight[slug as SlugEnum] || undefined,
  );
  useEffect(() => {
    if (!banner) return;
    if (hideTransversalBanner(banner.slug, hide_banners)) {
      setHeight('0');
    }
  }, [banner, device]);

  useEffect(() => {
    if (!wrapperRef.current) return;
    if (banner?.slug !== 'all_sidebar') return;
    const resizeObserver = new ResizeObserver(() => {
      const clientHeight = wrapperRef.current?.clientHeight;
      const newHeight = clientHeight ? `${clientHeight}px` : '0';
      setHeight(newHeight);
    });
    resizeObserver.observe(wrapperRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  if (!banner) return <></>;

  const showTemplate =
    slug === SlugEnum.matter_before_sharing &&
    (contacts.editors_tg_bot || contacts.editors_whatsapp);

  if (showTemplate) {
    return (
      <BannerTemplate
        className={className}
        telegram={contacts.editors_tg_bot}
        whatsapp={contacts.editors_whatsapp}
      />
    );
  }

  const Component = banner.provider === 'adfox' ? AdfoxBanner : HtmlBanner;

  return (
    <div
      className={className}
      data-slug={banner.slug}
      data-provider={banner.provider}
      data-init-height={bannersHeight[slug as SlugEnum]}
      style={{
        height,
      }}
    >
      <div ref={wrapperRef}>
        <ErrorBoundary>
          <Component onRender={onRender} banner={banner} reload={reload} />
        </ErrorBoundary>
      </div>
    </div>
  );
};

export default Banner;
