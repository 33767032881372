import hexRgb from 'hex-rgb';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import React, { useMemo } from 'react';
import CookieConsentPanel from 'src/pages/cookies';

import { useDevice } from '@/helpers/deviceContext';
import { usePlatform } from '@/helpers/platformContext';
import { SlugEnum } from '@/helpers/slugEnums';
import useUserSource from '@/helpers/useUserSource';

import HeaderBiddings from '@/components/Banner/HeaderBiddings';
import SeoData from '@/components/SeoData';
import Tolstoycomments from '@/components/Tolstoycomments';
import Topline from '@/components/Topline';

import Banner from './Banner';
import BottomFixedStack from './BottomFixedStack';
import Footer from './Footer';

const Counters = dynamic(() => import('@/components/Counters'), { ssr: false });
const TopProgressBar = dynamic(() => import('@/components/TopProgressBar'), {
  ssr: false,
});

const Layout: React.FC = ({ children }: React.PropsWithChildren) => {
  let isMobile;

  try {
    const device = useDevice();
    isMobile = device.isMobile;
  } catch (e) {
    console.error(e);
    isMobile = false;
  }
  const platform = usePlatform();
  const userSource = useUserSource();
  // const router = useRouter();
  const activeColorHex = platform.color || '#F4213B';
  const { red, green, blue } = useMemo(
    () => hexRgb(activeColorHex),
    [activeColorHex],
  );
  const inHeadContent = platform.banners
    ? isMobile
      ? platform.banners.find((el) => el.slug === SlugEnum.in_head)?.code_mobile
      : platform.banners.find((el) => el.slug === SlugEnum.in_head)
          ?.code_desktop
    : null;
  const faviconType = platform.favicon_url?.endsWith('.ico')
    ? 'image/x-icon'
    : 'image/png';

  return (
    <>
      <Head>
        {inHeadContent && (
          <div dangerouslySetInnerHTML={{ __html: inHeadContent }} />
        )}
        <style>
          {`:root {--active-color: ${activeColorHex}; --active-color-red: ${red}; --active-color-green: ${green}; --active-color-blue: ${blue}; }`}
        </style>
        <link
          type={faviconType}
          rel="shortcut icon"
          href={platform.favicon_url}
        />
        {platform.favicon_svg_url && (
          <link
            rel="icon"
            href={platform.favicon_svg_url}
            type="image/svg+xml"
          />
        )}
        <meta
          name="viewport"
          content={isMobile ? 'width=device-width' : 'width=1200'}
        />
        <meta
          name="yandex-verification"
          content={platform.analytics.yandex_verification}
        />
        <meta
          name="google-site-verification"
          content={platform.analytics.google_verification}
        />
        <script dangerouslySetInnerHTML={{ __html: `` }}></script>
        <script type="text/javascript">
          {'window.dataLayer = window.dataLayer || []'}
        </script>
        {!!platform.settings_custom.header_biddings && (
          <>
            <script
              src="https://yandex.ru/ads/system/header-bidding.js"
              async
            ></script>
            {/*<script>
              {`window.YaHeaderBiddingSettings = ${JSON.stringify(platform.settings_custom.header_biddings)}`}
            </script>*/}
          </>
        )}
        <script>
          {`window.Ya || (window.Ya = {});
          window.yaContextCb = window.yaContextCb || [];
          window.Ya.adfoxCode || (window.Ya.adfoxCode = {});
          window.Ya.adfoxCode.hbCallbacks || (window.Ya.adfoxCode.hbCallbacks = [])`}
        </script>
        <script src="https://yandex.ru/ads/system/context.js" async></script>
      </Head>
      <SeoData
        item={{
          ...platform,
          header: platform.meta_information_attributes?.title || platform.title,
        }}
      />
      <TopProgressBar />
      <Counters />
      <Tolstoycomments />
      <div className="min-h-[95vh]" data-desktop={isMobile ? undefined : true}>
        <Topline />
        {children}
      </div>
      <HeaderBiddings settings={platform.settings_custom.header_biddings} />
      {userSource && platform.hostname !== 'https://properm.ru' && (
        <Banner slug={SlugEnum.all_catfish} />
      )}
      {platform.hostname === 'https://properm.ru' && (
        <div>
          <Banner slug={SlugEnum.all_catfish} />
        </div>
      )}
      {/* все элементы в нижнем стаке */}
      <BottomFixedStack>
        {isMobile && (
          <div style={{ padding: '0 16px' }}>
            <Banner slug={SlugEnum.telegramm} className="tg-banner_mobile" />
          </div>
        )}

        <div style={{ padding: '0 16px' }}>
          <CookieConsentPanel />
        </div>
      </BottomFixedStack>

      <div
        dangerouslySetInnerHTML={{
          __html: platform.custom_html_in_body,
        }}
      />
      <Footer />
    </>
  );
};

export default Layout;
