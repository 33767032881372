import React, { Dispatch, useReducer } from 'react';

import { createAppContext } from '@/helpers/createContext';

type ToplineActionType = {
  type:
    | 'SET_LIVE_VISIBLE'
    | 'SET_MENU_VISIBLE'
    | 'SET_SEARCH_VISIBLE'
    | 'SET_TRANSPARENT';
  payload: boolean;
};

export type ToplineStateType = {
  liveVisible: boolean;
  menuVisible: boolean;
  searchVisible: boolean;
  isTransparent: boolean;
};

const reducer = (
  state: ToplineStateType,
  action: ToplineActionType,
): ToplineStateType => {
  switch (action.type) {
    case 'SET_LIVE_VISIBLE':
      return {
        ...state,
        liveVisible: action.payload,
      };
    case 'SET_MENU_VISIBLE':
      return {
        ...state,
        searchVisible: false,
        menuVisible: action.payload,
      };
    case 'SET_SEARCH_VISIBLE':
      return {
        ...state,
        menuVisible: false,
        searchVisible: action.payload,
      };
    case 'SET_TRANSPARENT':
      return {
        ...state,
        isTransparent: action.payload,
      };
    default:
      return state;
  }
};

const [useToplineReducer, Provider] =
  createAppContext<[ToplineStateType, Dispatch<ToplineActionType>]>(
    'topline context',
  );

const ToplineStateProvider: React.FC<{
  initialState?: ToplineStateType;
  // @ts-expect-error @TODO fix types
}> = ({ children, initialState = {} }) => {
  const stateAndDispatch = useReducer(reducer, {
    liveVisible: false,
    menuVisible: false,
    searchVisible: false,
    isTransparent: true,
    ...initialState,
  });

  return <Provider value={stateAndDispatch}>{children}</Provider>;
};

export { useToplineReducer, ToplineStateProvider };
