import React from 'react';

import { createAppContext } from './createContext';
import { SlugEnum } from './slugEnums';
import { MatterType, TagType } from './types';

type BannersContext = {
  hide_banners: boolean;
  bannersHeight: Record<`${SlugEnum}`, string | undefined>;
};

const BannerContext = React.createContext(false);

export const [useBannerInitialContext, BannerInitialContext] =
  createAppContext<BannersContext>('BannerInitialContext');

export const getInitialBannerContext = (
  pageProps: object,
  bannersHeight: BannersContext['bannersHeight'],
): BannersContext => {
  let hide_banners = false;
  if ('initialMatters' in pageProps) {
    if ((pageProps.initialMatters as MatterType[] | TagType[]).length === 1) {
      // @ts-expect-error @TODO fix this
      // eslint-disable-next-line
      if ('hide_banners' in pageProps?.initialMatters[0]) {
        // @ts-expect-error @TODO fix this
        hide_banners = pageProps?.initialMatters[0].hide_banners as boolean;
      }
    }
  }

  return { hide_banners, bannersHeight };
};

export const hideTransversalBanner = (slug: SlugEnum, needHide: boolean) => {
  if (
    needHide &&
    (slug === SlugEnum.all_catfish ||
      slug === SlugEnum.all_fullscreen ||
      slug === SlugEnum.all_header_before)
  )
    return true;
  return false;
};

export default BannerContext;
