import classNames from 'classnames';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useDevice } from '@/helpers/deviceContext';
import { isBannerBranding } from '@/helpers/isBannerBranding';
import { usePlatform } from '@/helpers/platformContext';
import setBannerDate from '@/helpers/setBannerDate';
import { SlugEnum } from '@/helpers/slugEnums';
import { lockBody, unlockBody } from '@/helpers/toggleBodyScroll';

import Banner from '@/components/Banner';

import styles from '@/styles/components/Topline/Topline.module.scss';

import { useToplineReducer } from './toplineContext';

const ToplineSearch = dynamic(() => import('./ToplineSearch'));
const ToplineMenu = dynamic(() => import('./ToplineMenu'));
const ToplineTransparent = dynamic(
  () => import('@/components/Topline/ToplineTransparent'),
);
const ToplineDesktopTabs = dynamic(() => import('./ToplineDesktopTabs'));
const ToplineLive = dynamic(() => import('./ToplineLive'));

const Topline: React.FC = () => {
  const { isDesktop } = useDevice();
  const [{ liveVisible, menuVisible, searchVisible, isTransparent }, dispatch] =
    useToplineReducer();
  const { logo_url, title, domain, banners } = usePlatform();
  const router = useRouter();
  const ref = useRef<HTMLAnchorElement>(null);
  const isToplineTransparent = isTransparent;
  const [isHidden, setIsHidden] = useState(isToplineTransparent);
  const [transition, setTransition] = useState(false);
  const prevY = useRef(0);

  // page transitions hide menu and search, reset prevY
  useEffect(() => {
    dispatch({ type: 'SET_MENU_VISIBLE', payload: false });
    dispatch({ type: 'SET_SEARCH_VISIBLE', payload: false });
    prevY.current = 0;
  }, [router.route, dispatch]);

  // page transitions show/hide topline
  useEffect(() => {
    setIsHidden(isToplineTransparent);
  }, [isToplineTransparent]);

  useEffect(() => {
    if (searchVisible || menuVisible) {
      lockBody();
    } else {
      unlockBody();
    }
  }, [searchVisible, menuVisible]);

  // page scroll show/hide topline
  useEffect(() => {
    function scrollHandler() {
      const scrollY = window.scrollY;
      const direction = prevY.current < scrollY ? 'down' : 'up';
      prevY.current = scrollY;

      let currentIsHidden =
        direction === 'down' && scrollY > 500 && !menuVisible;
      if (isToplineTransparent && scrollY < 500) currentIsHidden = true;
      if (isHidden !== currentIsHidden) {
        setIsHidden(currentIsHidden);
        setTransition(true);
      }
    }

    window.addEventListener('scroll', scrollHandler, { passive: true });
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [isHidden, isToplineTransparent, menuVisible]);

  const clickLogoCapture = (e: SyntheticEvent) => {
    if (ref.current?.href) {
      ref.current.href = menuVisible ? '#' : '/';
      if (menuVisible) {
        dispatch({ type: 'SET_MENU_VISIBLE', payload: false });
        e.preventDefault();
      }
    }
  };
  const onBannerRender = useCallback(() => {
    void setBannerDate({ domain: domain, slug: SlugEnum.all_header_before });
  }, []);
  return (
    <>
      {/* page top margin */}
      <div
        data-testid="Topline_index"
        hidden={isToplineTransparent}
        style={{ height: liveVisible ? '91px' : '57px' }}
      />
      <Banner
        data-testid="Banner_all_header_before"
        slug={SlugEnum.all_header_before}
        className="bg-[#333]"
        onRender={onBannerRender}
      />
      <div className={classNames({ relative: !searchVisible && !menuVisible })}>
        {searchVisible && <ToplineSearch />}
        {menuVisible && (
          <ToplineMenu
            onClose={() =>
              dispatch({ type: 'SET_MENU_VISIBLE', payload: false })
            }
          />
        )}
        {isToplineTransparent && <ToplineTransparent />}
        <div
          className={classNames(styles.topline, {
            [styles.__hidden]: !isHidden,
            [styles.__transition]: transition,
          })}
        >
          <div data-testid="Topline_menu" className={styles.menu}>
            {menuVisible ? (
              <button
                type="button"
                className={styles.close}
                onClick={() =>
                  dispatch({ type: 'SET_MENU_VISIBLE', payload: false })
                }
              />
            ) : (
              <button
                type="button"
                className={styles.menuTrigger}
                onClick={() =>
                  dispatch({ type: 'SET_MENU_VISIBLE', payload: true })
                }
              />
            )}
            <a
              href="/"
              ref={ref}
              className={styles.logo}
              onClickCapture={clickLogoCapture}
            >
              <img
                src={
                  logo_url ||
                  'https://static.tildacdn.com/tild3563-6535-4065-a162-373933396564/1mi-2021-email-opaci.png'
                }
                alt={title}
              />
            </a>

            {isDesktop && <ToplineDesktopTabs />}
            {isBannerBranding(banners) && !menuVisible && !searchVisible && (
              <Banner slug={SlugEnum.all_header_inner} className="mr-2" />
            )}
            {searchVisible ? (
              <button
                type="button"
                className={styles.close}
                onClick={() =>
                  dispatch({ type: 'SET_SEARCH_VISIBLE', payload: false })
                }
              />
            ) : !isBannerBranding(banners) || isDesktop || menuVisible ? (
              <button
                type="button"
                className={classNames(styles.searchTrigger, {
                  'opacity-0': router.route === '/search',
                })}
                onClick={() =>
                  dispatch({ type: 'SET_SEARCH_VISIBLE', payload: true })
                }
              />
            ) : null}
          </div>
          {!searchVisible && !menuVisible && <ToplineLive />}
        </div>
      </div>
    </>
  );
};

export default Topline;
