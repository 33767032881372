import { getSelectorsByUserAgent } from 'react-device-detect';

export type DeviceType = {
  isMobile: boolean;
  isDesktop: boolean;
};

export default function deviceDetector(ua: string): DeviceType {
  const res = getSelectorsByUserAgent(ua) as {
    isMobileOnly: boolean;
  };
  const isMobile = res?.isMobileOnly;
  return {
    isMobile,
    isDesktop: !isMobile,
  };
}
