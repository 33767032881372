import React, { useEffect } from 'react';

import { usePlatform } from '@/helpers/platformContext';

const Tolstoycomments: React.FC = () => {
  const platform = usePlatform();

  useEffect(() => {
    // without timeout got error: tc: settings loading failed, because script was removed from head
    setTimeout(insertTolstoycommentsScript, 500); // maybe 200 will be enough
  }, []);

  /* eslint-disable */
  function insertTolstoycommentsScript(): void {
    if (!platform.tolstoycomments_id) return;
    (function (w, d, s, l, x) {
      // @ts-ignore
      w[l] = w[l] || [];
      // @ts-ignore
      w[l].t = w[l].t || new Date().getTime(); // set fixed timestamp for debug
      // @ts-ignore
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s);
      // @ts-ignore
      j.async = !0;
      // @ts-ignore
      j.src =
        '//web.tolstoycomments.com/sitejs/app.js?i=' +
        l +
        '&x=' +
        x +
        '&t=' +
        // @ts-ignore
        w[l].t;
      // @ts-ignore
      f.parentNode.insertBefore(j, f);
    })(
      window,
      document,
      'script',
      'tolstoycomments',
      platform.tolstoycomments_id,
    );
  }

  return null;
};

export default Tolstoycomments;
