export enum SlugEnum {
  in_head = 'in_head',
  all_sidebar = 'all_sidebar',
  all_header_before = 'all_header_before',
  all_header_inner = 'all_header_inner',
  branding_left = 'branding_left',
  branding_right = 'branding_right',
  mainpage_feed = 'mainpage_feed',
  mainpage_feed_3 = 'mainpage_feed_3',
  mainpage_story_after = 'mainpage_story_after',
  mainpage_story_after_2 = 'mainpage_story_after_2',
  matter_content_long = 'matter_content_long',
  matter_teasers_before = 'matter_teasers_before',
  matter_page_after = 'matter_page_after',
  matter_page_after_2 = 'matter_page_after_2',
  matter_before_sharing = 'matter_before_sharing',
  all_catfish = 'all_catfish',
  all_fullscreen = 'all_fullscreen',
  telegramm = 'telegram_banner',
}
