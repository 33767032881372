import React, { useEffect } from 'react';

import { HeaderBiddingsSettingsType } from '@/helpers/types';

declare global {
  interface Window {
    YaHeaderBiddingSettings?: HeaderBiddingsSettingsType;
  }
}

const HeaderBiddings: React.FC<{
  settings?: HeaderBiddingsSettingsType;
}> = ({ settings }): React.ReactElement => {
  useEffect(() => {
    window.YaHeaderBiddingSettings = settings;
  }, [settings]);

  return <></>;
};

export default React.memo(HeaderBiddings);
