import { useEffect, useState } from 'react';

export type ItemType =
  | 'zen'
  | 'tg'
  | 'ya'
  | 'vk'
  | 'tw'
  | 'ig'
  | 'ok'
  | 'fb'
  | 'goog'
  | 'yt'
  | 'tk';

function getFirstItemByUtm(): ItemType | null {
  const params = new URLSearchParams(location.search);
  const sourceParam = params.get('utm_source') || '';
  switch (sourceParam) {
    case 'yxnews':
      return 'ya';
    case 'ok.ru':
      return 'ok';
    case 'ok_button':
      return 'ok';
    case 'vk.com':
      return 'vk';
    case 'vk_button':
      return 'vk';
    case 'facebook.com':
      return 'fb';
    case 'fb_button':
      return 'fb';
    case 'instagram.com':
      return 'ig';
    case 'telegram':
      return 'tg';
    case 'twitter.com':
      return 'tw';
    case 'tiktok':
      return 'tk';
    default:
      return null;
  }
}

function getFirstItemByReferer(): ItemType | null {
  const referer = document.referrer;
  if (referer.includes('youtube.com')) return 'yt';
  if (referer.includes('zen.yandex')) return 'zen';
  if (referer.includes('yandex') && !referer.includes('suggest')) return 'ya';
  if (referer.includes('google') || referer.includes('ampproject'))
    return 'goog';
  if (referer.includes('facebook')) return 'fb';
  if (
    referer.includes('telegram') ||
    referer.includes('t.me') ||
    referer.includes('tlgrm.ru')
  )
    return 'tg';
  if (referer.includes('ok.ru')) return 'ok';
  if (referer.includes('vk.com')) return 'vk';
  if (referer.includes('tiktok')) return 'tk';

  return null;
}

function getUserSource(): ItemType | null {
  let userSource = getFirstItemByUtm();
  if (!userSource) userSource = getFirstItemByReferer();

  return userSource;
}

const useUserSource: () => ItemType | null = () => {
  const [userSource, setUserSource] = useState(null as ItemType | null);

  useEffect(() => {
    setUserSource(getUserSource());
  }, []);

  return userSource;
};

export default useUserSource;
